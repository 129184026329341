import {
    useContext,
} from 'react';

import { DashboardContext } from './Dashboard';

import styles from './Dashboard.module.css';

const BalanceSummary = props => {
    const {
        balance,
    } = useContext(DashboardContext);

    const balanceClass = (balance < 0 ? styles.negative : (balance > 0 ? styles.positive : undefined));

    return (
        <div>
            Balance (all-time): <div className={balanceClass}>{balance}</div>
        </div>
    );
};

export default BalanceSummary;
