export const validateFields = fields => {
    const {
        date,
        cost,
        locationId,
        costPerPlayer,
        sendEmail,
        emailContents,
        edit = false,
    } = fields;

    if (date === undefined || date === '' || date === 0) return false;
    if (cost === undefined || cost === '') return false;
    if (locationId === undefined || locationId === '' || locationId === 0) return false;
    if (costPerPlayer === undefined || costPerPlayer === '') return false;
    if (edit) return true;
    if (sendEmail &&
        (emailContents === undefined || emailContents === '')) return false;
    return true;
};

export const DefaultEmailText = `We have basketball on {DatePlaceholder} at the {LocationPlaceholder} Gym at {TimePlaceholder}. 
You can QuickPay/Zelle (to this email or my cell- 773-354-6852) me \${CostPerPlayerPlaceholder} (reminder- you can pay afterwards). 
{LocationNotesPlaceholder}
{LocationURLPlaceholder}

Who's in? I'm {InOutGTDPlaceholder}.

{MiscellaneousPlaceholder}`;

export const getEmailContents = (date, location, costPerPlayer, locationNotes, locationURL, inOutGTD, miscellaneous) => {
    const getDateString = date => {
        const options = { weekday: 'long', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const getTimeString = date => {
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleTimeString('en-US', options);
    };

    const getInOutGtdText = inOutGTD => {
        switch (inOutGTD) {
            case 0:
                return 'in';
            case 1:
                return 'out';
            case 2:
                return 'GTD';
            default:
                return 'in';
        }
    };

    return DefaultEmailText
        .replace('{DatePlaceholder}', getDateString(date))
        .replace('{LocationPlaceholder}', location)
        .replace('{TimePlaceholder}', getTimeString(date))
        .replace('{CostPerPlayerPlaceholder}', costPerPlayer)
        .replace('{LocationNotesPlaceholder}', locationNotes)
        .replace('{LocationURLPlaceholder}', (locationURL !== null ? locationURL : ""))
        .replace('{InOutGTDPlaceholder}', getInOutGtdText(inOutGTD))
        .replace('{MiscellaneousPlaceholder}', miscellaneous);
};

export const getEmailSubject = date => {
    return `${date.toLocaleDateString('en-US', { weekday: 'long' })} Basketball ${date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' })}`;
};