import {
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    TextField,
} from '@fluentui/react';

import { EditContext } from './Edit';
import { authPOST } from '../../../util/fetch';

const GameParticipantAmountPaid = props => {
    const {
        participant_id,
        amount_paid,
    } = props;

    const {
        game_id,
        updateGameParticipants,
    } = useContext(EditContext);

    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(amount_paid);

    useEffect(() => {
        setValue(amount_paid);
    }, [amount_paid]);

    const onChange = useCallback((_, value) => {
        setValue(value);
        setEditing(true);
    }, [setValue, setEditing]);

    const onBlur = useCallback(() => {
        if (!editing) return;

        authPOST(`games/${game_id}/pay`, {
            participant_id,
            amount_paid: parseFloat(value),
        })
        .then(({ error }) => {
            if (error) {
                alert(error);
                return;
            }
            updateGameParticipants();
            setEditing(false);
        });
    }, [
        editing,
        value,
        game_id,
        participant_id,
        updateGameParticipants,
        setEditing
    ]);

    const textFieldStyle = editing ? { fontStyle: 'italic' } : {};

    return (
        <TextField
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            style={textFieldStyle}
            title={"Manually Set Amount Paid"}
        />
    );
};

export default GameParticipantAmountPaid;