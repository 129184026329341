import { useContext } from 'react';

import {
    AddContext,
} from './Add';

import ContextTextField from '../../ContextTextField';

const MiscEmailTextField = props => {
    const {
        sendEmail,
    } = useContext(AddContext);

    if (!sendEmail) return null;

    return (
        <ContextTextField
            label={"Miscellaneous Email Text"}
            value={"miscEmailText"}
            setValue={"setMiscEmailText"}
            context={AddContext}
            multiline={true}
            placeholder={"Miscellaneous email text"}
            rows={5}
        />
    );
};

export default MiscEmailTextField;