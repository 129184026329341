import {
    useCallback,
    useContext,
} from 'react';

import { EditContext } from './Edit';
import { authDELETE } from '../../../util/fetch';
import { IconButton } from '@fluentui/react';

const iconProps = {
    iconName: 'Delete',
};

const RemoveGameParticipantButton = props => {
    const {
        participant_id,
    } = props;

    const {
        game_id,
        updateGameParticipants,
    } = useContext(EditContext);

    const onClick = useCallback(() => {
        if (!window.confirm('Are you sure you want to remove this participant?')) return;
        authDELETE(`games/${game_id}/remove_participant/${participant_id}`)
        .then(({ error }) => {
            if (error) {
                alert(error);
                return;
            }
            updateGameParticipants();
        });
    }, [game_id, participant_id, updateGameParticipants]);

    return (
        <>
            <IconButton
                iconProps={iconProps}
                title={"Remove Participant"}
                ariaLabel={"Remove Participant"}
                onClick={onClick}
            />
        </>
    );
};

export default RemoveGameParticipantButton;
