import Navi from '../Navi';

import {
    useMediaQuery,
} from 'react-responsive';

import { useContext } from 'react';
import { AuthenticatedPageContext } from '../AuthenticatedPage';

import styles from './MainBody.module.css';

const MainBody = ({ children }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const {
        showNavi,
    } = useContext(AuthenticatedPageContext);

    return (
        <div className={styles.main}>
            {(!isTabletOrMobile || showNavi) &&
            <div className={(isTabletOrMobile ? styles.naviDivFull : styles.naviDiv)}>
                <Navi />
            </div>
            }
            {(!isTabletOrMobile || !showNavi) &&
            <div className={styles.mainBody}>
                {children}
            </div>
            }
        </div>
    );
};

export default MainBody;
