import {
    AddContext,
} from './Add';

import ContextTextField from '../../ContextTextField';

const LastNameTextField = props => {
    return (
        <ContextTextField
            label={"Last Name"}
            value={"lastName"}
            setValue={"setLastName"}
            context={AddContext}
        />
    );
};

export default LastNameTextField;