import {
    AddContext,
} from './Add';

import ContextTextField from '../../ContextTextField';

const FirstNameTextField = props => {
    return (
        <ContextTextField
            label={"First Name"}
            value={"firstName"}
            setValue={"setFirstName"}
            context={AddContext}
        />
    );
};

export default FirstNameTextField;