import {
    useContext,
    useMemo,
} from 'react';

import {
    EditContext,
} from './Edit';

import ContextTextField from '../../ContextTextField';

import styles from './Edit.module.css';

const NetCostTextField = props => {
    const {
        netCost,
    } = useContext(EditContext);

    const style = useMemo(() => ({
        color: netCost > 0 ? 'green' : (netCost < 0 ? 'red' : undefined),
    }), [netCost]);

    return (
        <div className={styles.netCostTextField}>
            <ContextTextField
                label={"Net Cost"}
                value={"netCost"}
                context={EditContext}
                style={style}
                readOnly
            />
        </div>
    );
};

export default NetCostTextField;