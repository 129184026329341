import {
    createContext,
    useCallback,
    useState,
} from 'react';

import {
    initializeIcons,
    ThemeProvider,
} from '@fluentui/react';

import AppRoutes from '../AppRoutes';

import './App.css';

import {
    resolveTheme,
    getLocalTheme,
    setLocalTheme,
    getLocalThemeName,
} from '../../util/theme';

initializeIcons();

const ThemeContext = createContext();

const App = props => {
    const [themeName, setThemeName] = useState(getLocalThemeName());
    const [activeTheme, setActiveTheme] = useState(getLocalTheme());

    const setTheme = useCallback(theme => {
        setLocalTheme(theme);
        setThemeName(theme);
        setActiveTheme(resolveTheme(theme));
    }, [setThemeName, setActiveTheme]);

    return (
        <ThemeProvider applyTo='body' theme={activeTheme}>
            <ThemeContext.Provider value={{
                theme: themeName,
                setTheme,
            }}>
                <AppRoutes/>
            </ThemeContext.Provider>
        </ThemeProvider>
    );
};

export { ThemeContext };
export default App;
