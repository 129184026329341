import {
    createContext,
    useState,
    useCallback,
    useMemo,
} from 'react';

import {
    Label,
} from '@fluentui/react/lib/Label';

import {
    useNavigate,
} from 'react-router-dom';

import FirstNameTextField from './FirstNameTextField';
import LastNameTextField from './LastNameTextField';
import EmailAddressTextField from './EmailAddressTextField';
import ActiveCheckbox from './ActiveCheckbox';
import MinorCheckbox from './MinorCheckbox ';
import AddButton from './AddButton';

import { authPOST } from '../../../util/fetch';
import { validateFields } from '../util.js';
import NotesTextField from './NotesTextField.js';

import styles from './Add.module.css';

const AddContext = createContext();

const Add = props => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [active, setActive] = useState(true);
    const [minor, setMinor] = useState(false);
    const [notes, setNotes] = useState("");
    const navigate = useNavigate();

    const onSubmit = useCallback(() => {
        authPOST('players/create', {
            first_name: firstName,
            last_name: lastName,
            email_address: emailAddress,
            active,
            minor,
            notes,
        })
        .then(({ error, player_id }) => {
            if (error) {
                alert(error);
                return;
            }
            navigate(`/players/edit/${player_id}`);
        });
    }, [navigate, firstName, lastName, emailAddress, active, minor, notes]);

    const isSubmitEnabled = useMemo(() => {
        return validateFields({
            firstName,
            emailAddress,
        });
    }, [firstName, emailAddress]);

    return (
        <AddContext.Provider value={{
            firstName, setFirstName,
            lastName, setLastName,
            emailAddress, setEmailAddress,
            active, setActive,
            minor, setMinor,
            notes, setNotes,
            onSubmit,
            isSubmitEnabled,
        }}>
            <div>
                <Label>
                    Add New Player
                </Label>
                <div className={styles.firstNameDiv}>
                    <FirstNameTextField/>
                </div>
                <div className={styles.lastNameDiv}>
                    <LastNameTextField/>
                </div>
                <div className={styles.emailAddressDiv}>
                    <EmailAddressTextField/>
                </div>
                <div className={styles.activeCheckboxDiv}>
                    <ActiveCheckbox/>
                </div>
                <div className={styles.minorCheckboxDiv}>
                    <MinorCheckbox/>
                </div>
                <div className={styles.notesDiv}>
                    <NotesTextField/>
                </div>
                <div>
                    <AddButton/>
                </div>
            </div>
        </AddContext.Provider>
    );
};

export { AddContext };
export default Add;
