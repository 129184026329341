import { useContext } from 'react';

import {
    AddContext,
} from './Add';

import ContextDropdown from '../../ContextDropdown';

const options = [
    {
        key: 0,
        text: 'In',
    },
    {
        key: 1,
        text: 'Out',
    },
    {
        key: 2,
        text: 'GTD',
    },
];

const InOutGtdDropdown = props => {
    const {
        sendEmail,
    } = useContext(AddContext);

    if (!sendEmail) return null;

    return (
        <ContextDropdown
            label={"In/Out/Gtd"}
            context={AddContext}
            options={options}
            selectedKey={"inOutGTD"}
            setSelectedKey={"setInOutGTD"}
        />
    );
};

export default InOutGtdDropdown;