import {
    useCallback,
    useContext,
} from 'react';

import {
    AddContext,
} from './Add';

import ContextLocationDropdown from '../../ContextLocationDropdown';

const LocationDropdown = props => {
    const {
        setLocationName,
        setLocationURL,
    } = useContext(AddContext);

    const onSelectedCallback = useCallback(info => {
        const {
            name,
            url = "",
        } = info;
        setLocationName(name);
        setLocationURL(url);
    }, [setLocationName, setLocationURL]);

    return (
        <ContextLocationDropdown
            selectedKey={"locationId"}
            setSelectedKey={"setLocationId"}
            context={AddContext}
            onSelectedCallback={onSelectedCallback}
        />
    );
};

export default LocationDropdown;