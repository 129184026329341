import {
    createContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from 'react';

import {
    Label,
} from '@fluentui/react/lib/Label';

import {
    useNavigate,
    useParams,
} from 'react-router-dom';

import DateTextField from './DateTextField';
import CostTextField from './CostTextField';
import LocationDropdown from './LocationDropdown';
import CostPerPlayerTextField from './CostPerPlayerTextField';
import UpdateButton from './UpdateButton';

import { authGET, authPUT } from '../../../util/fetch';
import { validateFields } from '../util';
import PlayerPicker from './PlayerPicker';
import GameParticipantList from './GameParticipantList';
import NetCostTextField from './NetCostTextField';

import styles from './Edit.module.css';

const EditContext = createContext();

const Edit = props => {
    const {
        id,
    } = useParams();

    const [date, setDate] = useState('');
    const [cost, setCost] = useState('');
    const [locationId, setLocationId] = useState('');
    const [costPerPlayer, setCostPerPlayer] = useState('');
    const [participants, setParticipants] = useState([]);
    const [totalCollected, setTotalCollected] = useState(0);
    const navigate = useNavigate();

    const updateGameParticipants = useCallback(() => {
        authGET(`games/${id}/participants`)
        .then(({ error, participants }) => {
            if (error) {
                alert(error);
                return;
            }
            setParticipants(participants);
            setTotalCollected(participants.reduce((acc, { amount_paid }) => acc + amount_paid, 0));
        });
    }, [id, setParticipants, setTotalCollected]);

    useEffect(() => {
        updateGameParticipants();
    }, [updateGameParticipants]);

    useEffect(() => {
        authGET(`games/${id}`)
        .then(({ error, date, cost, location_id, cost_per_player }) => {
            if (error) {
                alert(error);
                return;
            }
            setDate(new Date(date * 1000));
            setCost(cost);
            setLocationId(location_id);
            setCostPerPlayer(cost_per_player);
        });
    }, [id, setDate, setCost, setLocationId, setCostPerPlayer]);

    const onSubmit = useCallback(() => {
        authPUT(`games/update/${id}`, {
            date: (date.getTime() / 1000),
            cost: parseFloat(cost),
            location_id: locationId,
            cost_per_player: parseFloat(costPerPlayer),
        })
        .then(({ error }) => {
            if (error) {
                alert(error);
                return;
            }
            navigate(0);
        });
    }, [navigate, id, date, cost, locationId, costPerPlayer]);

    const isSubmitEnabled = useMemo(() => {
        return validateFields({
            date,
            cost,
            locationId,
            costPerPlayer,
            edit: true,
        });
    }, [date, cost, locationId, costPerPlayer]);

    const netCost = useMemo(() => {
        return totalCollected - cost;
    }, [totalCollected, cost]);

    return (
        <EditContext.Provider value={{
            game_id: id,
            date, setDate,
            cost, setCost,
            locationId, setLocationId,
            costPerPlayer, setCostPerPlayer,
            participants, setParticipants,
            netCost,
            updateGameParticipants,
            onSubmit,
            isSubmitEnabled,
        }}>
            <div className={styles.detailsDiv}>
                <Label>
                    Edit Game Details
                </Label>
                <div className={styles.dateTextDiv}>
                    <DateTextField/>
                </div>
                <div className={styles.costTextField}>
                    <CostTextField/>
                </div>
                <div className={styles.locationDropdownDiv}>
                    <LocationDropdown/>
                </div>
                <div>
                    <Label>
                        Cost Per Player
                    </Label>
                    <div className={styles.costPerPlayerTextField}>
                        <CostPerPlayerTextField/>
                    </div>
                </div>
                <div className={styles.updateButtonDiv}>
                    <UpdateButton/>
                </div>
            </div>
            <div className={styles.netCostDiv}>
                <NetCostTextField/>
            </div>
            <div className={styles.participantsDiv}>
                <Label>
                    Add Participant
                </Label>
                <div className={styles.playerPickerDiv}>
                    <PlayerPicker/>
                </div>
                <div>
                    <div>
                        <Label>
                            Game Participants ( {participants.length} )
                        </Label>
                    </div>
                    <div className={styles.participantListDiv}>
                        <GameParticipantList/>
                    </div>
                </div>
            </div>
        </EditContext.Provider>
    );
};

export { EditContext };
export default Edit;
