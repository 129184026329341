import {
    EditContext,
} from './Edit';

import ContextCheckbox from '../../ContextCheckbox';

const ActiveCheckbox = props => {
    return (
        <ContextCheckbox
            label={"Active"}
            checked={"active"}
            setChecked={"setActive"}
            context={EditContext}
        />
    );
};

export default ActiveCheckbox;