import {
    useCallback,
    useEffect,
    useState,
} from 'react';

import {
    Label,
} from '@fluentui/react/lib/Label';

import {
    GoogleOAuthProvider,
    GoogleLogin,
} from '@react-oauth/google';

import {
    useNavigate,
} from 'react-router-dom';

import {
    apiPOST,
} from '../../util/fetch';

import styles from './Login.module.css';

const loginCheck = () => {
    return (localStorage.getItem('google_credential') !== null);
};

const Login = props => {
    const navigate = useNavigate();

    const [, setGoogleAuthCredential] = useState(null);

    const onSuccess = useCallback(r => {
        const {
            credential,
        } = r;

        setGoogleAuthCredential(credential);
        localStorage.setItem('google_credential', credential);

        apiPOST('login', {
            credential
        })
        .then(data => {
            if (data.success) {
                const token = data.token;
                localStorage.setItem('token', token);
                navigate('/dashboard');
            }
            else {
                alert('Login failed');
                console.error('Login failed:', data.error);
            }
        })
        .catch(error => {
            alert('Login failed');
            console.error('Error:', error);
        });

    }, [navigate, setGoogleAuthCredential]);

    const onError = useCallback(e => {
        console.error("error:", e);
        alert(e);
    }, []);

    useEffect(() => {
        if (loginCheck()) {
            setGoogleAuthCredential(localStorage.getItem('google_credential'));
        }
    }, [setGoogleAuthCredential]);

    return (
        <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        >
            <div className={styles.login}>
                <div className={styles.titleDiv}>
                    <Label>Basketball Ledger App Login</Label>
                </div>
                <div className={styles.button}>
                    <GoogleLogin
                        onSuccess={onSuccess}
                        onError={onError}
                        useOneTap={true}
                    />
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default Login;
