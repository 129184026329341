import {
    createContext,
    useCallback,
    useEffect,
    useState,
} from 'react';

import {
    Label,
} from '@fluentui/react/lib/Label';

import {
    authGET,
} from '../../util/fetch';

import AuthenticatedPage from '../AuthenticatedPage';
import BalanceOwed from './BalanceOwed';
import BalanceSummary from './BalanceSummary';
import HistoricalBalance from './HistoricalBalance';
import RunningSumBalance from './RunningSumBalance';

import styles from './Dashboard.module.css';

const DashboardContext = createContext();

const Dashboard = props => {
    const [historicalBalance, setHistoricalBalance] = useState([]);
    const [players, setPlayers] = useState([]);
    const [balance, setBalance] = useState(0);

    const updateOwedAmounts = useCallback(() => {
        authGET('players/owed_amount')
        .then(({ players, error }) => {
            if (error) {
                alert(error);
                return;
            }
            setPlayers(players);
        })
        .catch(error => {
            alert('Error:', error);
        });
    }, [setPlayers]);

    const getHistoricalBalance = useCallback(() => {
        authGET('balance/history')
        .then(({ balance_history, error }) => {
            if (error) {
                alert(error);
                return;
            }
            const historicalBalance = [];
            balance_history.forEach(({ total_amount_collected, date }) => {
                historicalBalance.push([
                    total_amount_collected,
                    new Date(date * 1000),
                ]);
            });
            setHistoricalBalance(historicalBalance);
        })
        .catch(error => {
            alert('Error:', error);
        });
    }, []);

    const getBalance = useCallback(() => {
        authGET('balance')
        .then(({ error, balance = 0 }) => {
            if (error) {
                alert(error);
                return;
            }
            setBalance(balance);
        })
        .catch(error => {
            alert('Error:', error);
        });
    }, [setBalance]);

    const refreshData = useCallback(() => {
        getBalance();
        updateOwedAmounts();
        getHistoricalBalance();
    }, [
        getBalance,
        updateOwedAmounts,
        getHistoricalBalance,
    ]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    return (
        <AuthenticatedPage>
            <Label>
                Dashboard
            </Label>
            <DashboardContext.Provider value={{
                balance,
                historicalBalance,
                players,
                updateOwedAmounts,
                getHistoricalBalance,
                refreshData,
            }}>
                <div>
                    <div className={styles.dashboardSection}>
                        <BalanceSummary/>
                    </div>
                    <div className={styles.dashboardSection}>
                        <BalanceOwed/>
                    </div>
                    <div className={styles.dashboardSection}>
                        <RunningSumBalance/>
                    </div>
                    <div className={styles.dashboardSection}>
                        <HistoricalBalance/>
                    </div>
                </div>
            </DashboardContext.Provider>
        </AuthenticatedPage>
    );
};

export { DashboardContext };
export default Dashboard;
