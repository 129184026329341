import {
    useCallback,
    useContext,
} from 'react';

import {
    TextField,
} from '@fluentui/react';

const ContextTextField = props => {
    const {
        context,
        value: _value,
        setValue: _setValue,
        ...rest
    } = props;

    const {
        [_value] : value,
        [_setValue]: setValue,
    } = useContext(context);

    const onChange = useCallback((_, value) => {
        setValue(value);
    }, [setValue]);

    return (
        <TextField
            value={value}
            onChange={onChange}
            {...rest}
        />
    );
};

export default ContextTextField;