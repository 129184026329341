import Title from './Title';

import styles from './Header.module.css';
import ThemeToggle from './ThemeToggle';
import NaviToggle from './NaviToggle';

const Header = props => {
    return (
        <div className={styles.header}>
            <Title/>
            <NaviToggle/>
            <ThemeToggle/>
        </div>
    );
};

export default Header;