import {
    AddContext,
} from './Add';

import ContextTextField from '../../ContextTextField';

const NotesTextField = props => {
    return (
        <ContextTextField
            label={"Notes"}
            value={"notes"}
            setValue={"setNotes"}
            context={AddContext}
            multiline
        />
    );
};

export default NotesTextField;