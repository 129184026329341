import {
    AddContext,
} from './Add';

import ContextTextField from '../../ContextTextField';

const EmailAddressTextField = props => {
    return (
        <ContextTextField
            label={"Email Address"}
            value={"emailAddress"}
            setValue={"setEmailAddress"}
            context={AddContext}
        />
    );
};

export default EmailAddressTextField;