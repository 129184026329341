import {
    Link,
} from 'react-router-dom';

import styles from './Dashboard.module.css';
import BalanceOwedGameParticipant from './BalanceOwedGameParticipant';

const BalanceOwedPlayerBalance = ({ player }) => {
    const {
        first_name,
        last_name,
        owed_amount,
        player_id,
        owed_games_list,
    } = player;

    return (
        <div>
            <div className={styles.playerBalanceParentDiv}>
                <div className={styles.playerBalanceList}>
                    <div className={styles.playerBalanceNameDiv}>
                        <Link to={`/players/edit/${player_id}`}
                            className={styles.playerLink}
                        >
                            {first_name} {last_name}
                        </Link>:
                    </div>
                    <div className={styles.playerBalanceOwedAmountDiv}>
                        ${owed_amount}
                    </div>
                </div>
            </div>
            <div>
                {owed_games_list.map((participation_info, idx) =>
                    <BalanceOwedGameParticipant
                        idx={idx}
                        key={idx}
                        {...participation_info}
                        first_name={first_name}
                        last_name={last_name}
                    />
                )}
            </div>
        </div>
    );
};

export default BalanceOwedPlayerBalance;
