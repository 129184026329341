import {
    createContext,
    useState,
} from 'react';

import Header from './Header';
import MainBody from './MainBody';

import styles from './AuthenticatedPage.module.css';

const AuthenticatedPageContext = createContext();

const AuthenticatedPage = ({ children }) => {
    const [showNavi, setShowNavi] = useState(false);

    return (
        <div className={styles.page}>
            <AuthenticatedPageContext.Provider value={{
                showNavi, setShowNavi,
            }}>
                <Header/>
                <MainBody>
                    {children}
                </MainBody>
            </AuthenticatedPageContext.Provider>
        </div>
    );
};

export { AuthenticatedPageContext };
export default AuthenticatedPage;