import {
    AddContext,
} from './Add';

import ContextCheckbox from '../../ContextCheckbox';

const ActiveCheckbox = props => {
    return (
        <ContextCheckbox
            label={"Active"}
            checked={"active"}
            setChecked={"setActive"}
            context={AddContext}
        />
    );
};

export default ActiveCheckbox;