import {
    EditContext,
} from './Edit';

import ContextTextField from '../../ContextTextField';

const NotesTextField = props => {
    return (
        <ContextTextField
            label={"Notes"}
            value={"notes"}
            setValue={"setNotes"}
            context={EditContext}
            multiline
        />
    );
};

export default NotesTextField;