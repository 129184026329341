import {
    EditContext,
} from './Edit';

import ContextTextField from '../../ContextTextField';

const EmailAddressTextField = props => {
    return (
        <ContextTextField
            label={"Email Address"}
            value={"emailAddress"}
            setValue={"setEmailAddress"}
            context={EditContext}
        />
    );
};

export default EmailAddressTextField;