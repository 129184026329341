import {
    useCallback,
    useContext,
} from 'react';

import {
    Checkbox,
} from '@fluentui/react';

const ContextCheckbox = props => {
    const {
        context,
        checked: _checked,
        setChecked: _setChecked,
        ...rest
    } = props;

    const {
        [_checked] : checked,
        [_setChecked]: setChecked,
    } = useContext(context);

    const onChange = useCallback(() => {
        setChecked(!checked)
    }, [checked, setChecked]);

    return (
        <Checkbox
            checked={checked}
            onChange={onChange}
            {...rest}
        />
    );
};

export default ContextCheckbox;