import {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import { TagPicker } from '@fluentui/react/lib/Pickers';

import { EditContext } from './Edit';
import { authGET, authPOST } from '../../../util/fetch';

const getTextFromItem = item => {
    const {
        first_name,
        last_name,
    } = item;
    return `${first_name} ${last_name}`;
};

const listContainsTagList = (tag, tagList) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some(compareTag => compareTag.player_id === tag.player_id);
  };

const pickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested Players',
    noResultsFoundText: 'No players found',
};

const inputProps = {
    'aria-label': "PlayerPicker",
    onKeyPress: e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }
};

const PlayerPicker = props => {
    const [players, setPlayers] = useState([]);
    const pickerRef = useRef(null);

    const {
        game_id,
        participants: gameParticipants,
        updateGameParticipants,
    } = useContext(EditContext);

    useEffect(() => {
        authGET('players')
        .then(({ error, players }) => {
            if (error) {
                alert(error);
                return;
            }
            setPlayers(players.map(player => {
                const {
                    first_name,
                    last_name,
                } = player;
                return {
                    ...player,
                    search_text: `${first_name} ${last_name}`,
                    name: `${first_name} ${last_name}`,
                };
            }));
        })
        .catch(error => {
            alert('Error:', error);
        });
    }, [setPlayers]);

    const onResolveSuggestions = useCallback((filterText, tagList) => {
        return filterText
          ? players.filter(tag => tag.search_text.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, gameParticipants))
          : [];
    }, [players, gameParticipants]);

    const onChange = useCallback(items => {
        authPOST(`games/${game_id}/add_participant`, {
            player_id: items[0].player_id,
        })
        .then(({ error }) => {
            if (error) {
                alert(error);
                return;
            }
            updateGameParticipants();
        });
    }, [game_id, updateGameParticipants]);

    const onEmptyResolveSuggestions = useCallback(() => {
        const filteredPlayers = players.filter(player => !listContainsTagList(player, gameParticipants));
        const sortedPlayers = filteredPlayers.sort((a, b) => b.participation_count - a.participation_count);
        return sortedPlayers.slice(0, 5);
    }, [players, gameParticipants]);

    return (
        <TagPicker
            componentRef={pickerRef}
            onResolveSuggestions={onResolveSuggestions}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={pickerSuggestionsProps}
            inputProps={inputProps}
            onChange={onChange}
            itemLimit={1}
            selectedItems={[]}
            onEmptyResolveSuggestions={onEmptyResolveSuggestions}
        />
    )
};

export default PlayerPicker;
