import {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    useNavigate,
} from 'react-router-dom';

import Login from '../Login';
import Dashboard from '../Dashboard';
import Locations from '../Locations';
import Games from '../Games';
import Players from '../Players';

const loginCheck = () => {
    return (localStorage.getItem('token') !== null);
};

const OAuth2Context = createContext();

const AuthenticatedElement = ({ children }) => {
    const navigate = useNavigate();

    // for caching the auth response
    const [authResponse_, setAuthResponse_] = useState(undefined);

    useEffect(() => {
        const authResponse = localStorage.getItem('authResponse');
        if (authResponse !== null) {
            setAuthResponse_(JSON.parse(authResponse));
        }
    }, []);

    const getAuthResponse = useCallback(() => {
        if (authResponse_ === undefined) {
            return undefined;
        }
        const {
            expy = 0,
            response,
        } = authResponse_;
        if (expy < Date.now()) {
            localStorage.removeItem('authResponse');
            return undefined;
        }
        return response;
    }, [authResponse_]);

    const setAuthResponse = useCallback(response => {
        if (response === undefined) {
            return;
        }
        const {
            expires_in = 0,
        } = response;
        const d = new Date();
        d.setSeconds(d.getSeconds() + expires_in - 60);
        const r = {
            expy: d.getTime(),
            response,
        };
        setAuthResponse_(r);
        localStorage.setItem('authResponse', JSON.stringify(r));
    }, [setAuthResponse_]);

    useEffect(() => {
        if (!loginCheck()) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <OAuth2Context.Provider value={{
            getAuthResponse,
            setAuthResponse,
        }}>
            {children}
        </OAuth2Context.Provider>
    );
};

const AppRoutes = () => {
    const router = useMemo(() => {
        return createBrowserRouter(
            createRoutesFromElements(
                <>
                    <Route path="/" exact element={
                        <AuthenticatedElement>
                            <Dashboard/>
                        </AuthenticatedElement>
                    }/>
                    <Route path="/login" exact element={
                        <Login
                            loginCheck={loginCheck}
                        />
                    }/>
                    <Route path="/dashboard" exact element={
                        <AuthenticatedElement>
                            <Dashboard/>
                        </AuthenticatedElement>
                    }/>
                    <Route path="/locations" element={
                        <AuthenticatedElement>
                            <Locations/>
                        </AuthenticatedElement>
                    }/>
                    <Route path="/locations/:t" element={
                        <AuthenticatedElement>
                            <Locations/>
                        </AuthenticatedElement>
                    }/>
                    <Route path="/locations/:t/:id" element={
                        <AuthenticatedElement>
                            <Locations/>
                        </AuthenticatedElement>
                    }/>
                    <Route path="/games" element={
                        <AuthenticatedElement>
                            <Games/>
                        </AuthenticatedElement>
                    }/>
                    <Route path="/games/:t" element={
                        <AuthenticatedElement>
                            <Games/>
                        </AuthenticatedElement>
                    }/>
                    <Route path="/games/:t/:id" element={
                        <AuthenticatedElement>
                            <Games/>
                        </AuthenticatedElement>
                    }/>
                    <Route path="/players" element={
                        <AuthenticatedElement>
                            <Players/>
                        </AuthenticatedElement>
                    }/>
                    <Route path="/players/:t" element={
                        <AuthenticatedElement>
                            <Players/>
                        </AuthenticatedElement>
                    }/>
                    <Route path="/players/:t/:id" element={
                        <AuthenticatedElement>
                            <Players/>
                        </AuthenticatedElement>
                    }/>
                </>
            )
        );
    }, []);

    return (
        <RouterProvider router={router}/>
    );
};

export { OAuth2Context };
export default AppRoutes;