import {
    EditContext,
} from './Edit';

import ContextTextField from '../../ContextTextField';

const CostPerPlayerTextField = props => {
    return (
        <ContextTextField
            value={"costPerPlayer"}
            setValue={"setCostPerPlayer"}
            context={EditContext}
        />
    );
};

export default CostPerPlayerTextField;