import {
    useContext,
} from 'react';

import { ThemeContext } from '../../App/App';

import DarkThemeToggle from './DarkThemeToggle';
import DefaultThemeToggle from './DefaultThemeToggle';

import styles from './Header.module.css';

const ThemeToggle = props => {
    const {
        theme,
    } = useContext(ThemeContext);

    return (
        <div className={styles.toggleDiv}>
            {theme === "dark" &&
            <DarkThemeToggle/>
            }
            {theme === "default" &&
            <DefaultThemeToggle/>
            }
        </div>
    )
};

export default ThemeToggle;