import {
    useCallback,
    useContext,
} from 'react';

import {
    IconButton,
} from '@fluentui/react/lib/Button';

import { ThemeContext } from '../../App/App';

const iconProps = {
    iconName: "Sunny",
};

const DefaultThemeToggle = props => {
    const {
        setTheme,
    } = useContext(ThemeContext);

    const onClick = useCallback(() => {
        setTheme("dark");
    }, [setTheme]);

    return (
        <IconButton
            iconProps={iconProps}
            onClick={onClick}
        />
    )
};

export default DefaultThemeToggle;