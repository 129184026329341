import {
    useCallback,
    useContext,
} from 'react';

import { EditContext } from './Edit';
import { authPOST } from '../../../util/fetch';
import { IconButton } from '@fluentui/react';

const iconProps = {
    iconName: 'Money',
};

const GameParticipantPaidButton = props => {
    const {
        participant_id,
        amount_paid,
    } = props;

    const {
        game_id,
        costPerPlayer,
        updateGameParticipants,
    } = useContext(EditContext);

    const onClick = useCallback(() => {
        authPOST(`games/${game_id}/pay`, {
            participant_id,
            amount_paid: costPerPlayer,
        })
        .then(({ error }) => {
            if (error) {
                alert(error);
                return;
            }
            updateGameParticipants();
        });
    }, [game_id, participant_id, costPerPlayer, updateGameParticipants]);

    return (
        <>
            <IconButton
                iconProps={iconProps}
                title={"Mark as Paid"}
                ariaLabel={"Mark as Paid"}
                onClick={onClick}
                disabled={amount_paid >= costPerPlayer}
            />
        </>
    );
};

export default GameParticipantPaidButton;
