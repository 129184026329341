export const apiFetch = (url, options = {}) => {
    return fetch(`${process.env.REACT_APP_URL}/${url}`, options)
    .then(response => response.json());
};

export const apiPOST = (url, body) => {
    return apiFetch(url, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
        },
        body: JSON.stringify(body),
    });
};

export const authPOST = (url, body) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }
    return apiFetch(url, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });
};

export const authGET = url => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }
    return apiFetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
};

export const authDELETE = url => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }
    return apiFetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
};

export const authPUT = (url, body) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }
    return apiFetch(url, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });
};
