import {
    createContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from 'react';

import {
    Label,
} from '@fluentui/react/lib/Label';

import {
    useNavigate,
    useParams,
} from 'react-router-dom';

import NameTextField from './NameTextField';
import AddressTextField from './AddressTextField';
import MapsLinkTextField from './MapsLinkTextField';
import UpdateButton from './UpdateButton';

import { authGET, authPUT } from '../../../util/fetch';
import { validateFields } from '../util';

import styles from './Edit.module.css';

const EditContext = createContext();

const Edit = props => {
    const {
        id,
    } = useParams();

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [url, setURL] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        authGET(`locations/${id}`)
        .then(({ error, name, address, url = "" }) => {
            if (error) {
                alert(error);
                return;
            }
            setName(name);
            setAddress(address);
            setURL(url);
        });
    }, [
        id,
        setName,
        setAddress,
        setURL,
    ]);

    const onSubmit = useCallback(() => {
        authPUT(`locations/update/${id}`, {
            name,
            address,
            url,
        })
        .then(({ error }) => {
            if (error) {
                alert(error);
                return;
            }
            navigate(0);
        });
    }, [
        navigate,
        id,
        name,
        address,
        url,
    ]);

    const isSubmitEnabled = useMemo(() => {
        return validateFields({
            name,
        });
    }, [name]);

    return (
        <EditContext.Provider value={{
            name, setName,
            address, setAddress,
            url, setURL,
            onSubmit,
            isSubmitEnabled,
        }}>
            <div>
                <Label>
                    Edit Location
                </Label>
                <div className={styles.nameDiv}>
                    <NameTextField/>
                </div>
                <div className={styles.addressDiv}>
                    <AddressTextField/>
                </div>
                <div className={styles.urlDiv}>
                    <MapsLinkTextField/>
                </div>
                <div className={styles.btnDiv}>
                    <UpdateButton/>
                </div>
            </div>
        </EditContext.Provider>
    );
};

export { EditContext };
export default Edit;
