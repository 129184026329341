import {
    AddContext,
} from './Add';

import ContextCheckbox from '../../ContextCheckbox';

const SendEmailCheckbox = props => {
    return (
        <ContextCheckbox
            label={"Send Email"}
            checked={"sendEmail"}
            setChecked={"setSendEmail"}
            context={AddContext}
        />
    );
};

export default SendEmailCheckbox;