import {
    AddContext,
} from './Add';

import ContextCheckbox from '../../ContextCheckbox';

const MinorCheckbox = props => {
    return (
        <ContextCheckbox
            label={"Minor"}
            checked={"minor"}
            setChecked={"setMinor"}
            context={AddContext}
        />
    );
};

export default MinorCheckbox;