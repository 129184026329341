import {
    EditContext,
} from './Edit';

import ContextTextField from '../../ContextTextField';

const MapsLinkTextField  = props => {
    return (
        <ContextTextField
            label={"Maps URL"}
            value={"url"}
            setValue={"setURL"}
            context={EditContext}
        />
    );
};

export default MapsLinkTextField;