import {
    EditContext,
} from './Edit';

import ContextCheckbox from '../../ContextCheckbox';

const MinorCheckbox = props => {
    return (
        <ContextCheckbox
            label={"Minor"}
            checked={"minor"}
            setChecked={"setMinor"}
            context={EditContext}
        />
    );
};

export default MinorCheckbox;