import {
    EditContext,
} from './Edit';

import ContextTextField from '../../ContextTextField';

const AddressTextField = props => {
    return (
        <ContextTextField
            label={"Address"}
            value={"address"}
            setValue={"setAddress"}
            context={EditContext}
        />
    );
};

export default AddressTextField;