import {
    useCallback,
    useRef,
} from 'react';

import {
    useGridFilter,
} from 'ag-grid-react';

import {
    Dropdown,
} from '@fluentui/react';

import styles from './List.module.css';

const Options = [
    {
        key: null,
        text: '',
    },
    {
        key: 1,
        text: 'Yes',
    },
    {
        key: 0,
        text: 'No',
    },
];

const MinorFilter = ({ model, onModelChange }) => {
    const refInput = useRef(null);

    const doesFilterPass = useCallback(params => {
        if (model === null) return false;
        const {
            node: {
                data: {
                    minor = 0,
                } = {},
            } = {},
        } = params;
        if (model.value === null) return true;
        return model.value === minor;
    }, [model]);

    const afterGuiAttached = useCallback((params) => {
        if (!params || !params.suppressFocus) {
            refInput.current.focus();
        }
    }, []);

    const onChange = useCallback((e, { key }) => {
        if (key === null) {
            onModelChange(null);
            return;
        }
        onModelChange({ value: key });
    }, [onModelChange]);

    useGridFilter({
        doesFilterPass,
        afterGuiAttached,
    });

    return (
        <div className={styles.minorFilterDiv}>
            <Dropdown
                label={'Minor'}
                componentRef={refInput}
                selectedKey={null}
                onChange={onChange}
                options={Options}
            />
        </div>
    );
};

export default MinorFilter;