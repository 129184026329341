import {
    useCallback,
    useContext,
    useMemo,
} from 'react';

import {
    IconButton,
} from '@fluentui/react/lib/Button';

import {
    useMediaQuery,
} from 'react-responsive';

import styles from './Header.module.css';
import { AuthenticatedPageContext } from '../AuthenticatedPage';

const NaviToggle = props => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const {
        showNavi,
        setShowNavi,
    } = useContext(AuthenticatedPageContext);

    const onClick = useCallback(() => {
        setShowNavi(!showNavi);
    }, [setShowNavi, showNavi]);

    const iconProps = useMemo(() => {
        return {
            iconName: (showNavi ? "ChromeClose" : "NumberedListText"),
        };
    }, [showNavi]);

    if (!isTabletOrMobile) return null;
    return (
        <div className={styles.toggleDiv}>
            <IconButton
                iconProps={iconProps}
                onClick={onClick}
            />
        </div>
    );
};

export default NaviToggle;