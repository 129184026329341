import {
    AddContext,
} from './Add';

import ContextTextField from '../../ContextTextField';

const AddressTextField = props => {
    return (
        <ContextTextField
            label={"Address"}
            value={"address"}
            setValue={"setAddress"}
            context={AddContext}
        />
    );
};

export default AddressTextField;