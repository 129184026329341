import {
    useCallback,
    useContext,
} from 'react';

import {
    Label,
} from '@fluentui/react/lib/Label';

import {
    TimePicker,
    DatePicker,
} from '@fluentui/react';

import styles from './ContextDateTimePicker.module.css';

const ContextDateTimePicker = props => {
    const {
        context,
        value: _value,
        setValue: _setValue,
    } = props;

    const {
        [_value] : value,
        [_setValue]: setValue,
    } = useContext(context);

    const onSelectDate = useCallback((selectedDate) => {
        let snappedTime = new Date(value);

        if (value && !isNaN(value.valueOf())) {
            const startAnchor = new Date(selectedDate);
            const endAnchor = new Date(startAnchor);
            endAnchor.setDate(startAnchor.getDate() + 1);
            if (value < startAnchor || value > endAnchor) {
                snappedTime = new Date(startAnchor);
                snappedTime.setHours(value.getHours());
                snappedTime.setMinutes(value.getMinutes());
                snappedTime.setSeconds(value.getSeconds());
                snappedTime.setMilliseconds(value.getMilliseconds());
            }
        }

        setValue(snappedTime);
    }, [value, setValue]);
  
    const onTimePickerChange = useCallback((_, date) => {
        setValue(date);
    }, [setValue]);

    return (
        <div>
            <Label>Date</Label>
            <div className={styles.rowDiv}>
                <div className={styles.datePicker}>
                    <DatePicker
                        placeholder="Select a date..."
                        value={value}
                        onSelectDate={onSelectDate}
                        ariaLabel="Date picker"
                    />
                </div>
                <div className={styles.timePicker}>
                    <TimePicker
                        placeholder="Select a time"
                        dateAnchor={value}
                        value={value}
                        onChange={onTimePickerChange}
                        ariaLabel={"Time picker"}
                        useHour12
                        allowFreeform
                        autoComplete={"on"}
                        increments={15}
                    />
                </div>
            </div>
        </div>
    );
};

export default ContextDateTimePicker;