import {
    useContext,
} from 'react';

import {
    Label,
} from '@fluentui/react/lib/Label';

import { DashboardContext } from './Dashboard';

import BalanceOwedPlayerBalance from './BalanceOwedPlayerBalance';

import styles from './Dashboard.module.css';

const BalanceOwed = props => {
    const {
        players,
    } = useContext(DashboardContext);

    return (
        <div>
            <Label>
                Balance Owed
            </Label>
            <div className={styles.balanceOwedDiv}>
                {players.map((player, idx) =>
                    <BalanceOwedPlayerBalance
                        key={idx}
                        player={player}
                    />
                )}
            </div>
        </div>
    );
};

export default BalanceOwed;
