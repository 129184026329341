import {
    EditContext,
} from './Edit';

import ContextLocationDropdown from '../../ContextLocationDropdown';

const LocationDropdown = props => {
    return (
        <ContextLocationDropdown
            selectedKey={"locationId"}
            setSelectedKey={"setLocationId"}
            context={EditContext}
            useDefault={false}
        />
    );
};

export default LocationDropdown;