import {
    createContext,
    useState,
    useCallback,
    useContext,
    useMemo,
    useEffect,
} from 'react';

import {
    Label,
} from '@fluentui/react/lib/Label';

import {
    useNavigate,
} from 'react-router-dom';

import DateTextField from './DateTextField';
import CostTextField from './CostTextField';
import LocationDropdown from './LocationDropdown';
import CostPerPlayerTextField from './CostPerPlayerTextField';
import AddButton from './AddButton';
import SendEmailCheckbox from './SendEmailCheckbox.js';

import { authPOST } from '../../../util/fetch';
import { getEmailContents, getEmailSubject, validateFields } from '../util.js';
import { OAuth2Context } from '../../AppRoutes/AppRoutes.js';
import MiscEmailTextField from './MiscEmailTextField.js';
import EmailTextField from './EmailTextField.js';
import InOutGtdDropdown from './InOutGtdDropdown.js';

import styles from './Add.module.css';

const AddContext = createContext();

const getNextNewDate = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    if (dayOfWeek === 0) {
        today.setHours(20, 0, 0, 0);
        return today;
    }

    const nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + (7 - dayOfWeek + 0) % 7);
    nextSunday.setHours(20, 0, 0, 0);
    return nextSunday;
};

const Add = props => {
    const [date, setDate] = useState(getNextNewDate());
    const [cost, setCost] = useState('80'); // TODO: set default from somewhere
    const [locationId, setLocationId] = useState('');
    const [locationName, setLocationName] = useState('');
    const [locationNotes, setLocationNotes] = useState('');
    const [locationURL, setLocationURL] = useState('');
    const [costPerPlayer, setCostPerPlayer] = useState('10'); // TODO: set default from somewhere
    const [sendEmail, setSendEmail] = useState(true);
    const [inOutGTD, setInOutGTD] = useState(0);
    const [miscEmailText, setMiscEmailText] = useState('');
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(window.google !== undefined);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.id = 'google-auth-client-script';
        script.async = true;
        script.defer = true;
        script.addEventListener('load', () => {
            setLoaded(true);
        });
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [setLoaded]);

    const {
        getAuthResponse,
        setAuthResponse,
    } = useContext(OAuth2Context);

    const {
        emailContents,
        emailSubject,
    } = useMemo(() => {
        if (!sendEmail) return {
            emailContents: '',
            emailSubject: '',
        };
        return {
            emailContents: getEmailContents(
                date,
                locationName,
                costPerPlayer,
                locationNotes,
                locationURL,
                inOutGTD,
                miscEmailText
            ),
            emailSubject: getEmailSubject(date),
        };
    }, [
        sendEmail,
        date,
        locationName,
        costPerPlayer,
        locationNotes,
        locationURL,
        inOutGTD,
        miscEmailText,
    ]);

    const onCreateGame = useCallback(params => {
        authPOST('game/create', params)
        .then(({ error, game_id }) => {
            if (error) {
                alert(error);
                return;
            }
            navigate(`/games/edit/${game_id}`);
        });
    }, [navigate]);

    const onSubmit = useCallback(() => {
        if (!loaded) {
            alert("Google Sign-In not loaded. Try again in a few seconds.");
            return;
        }
        const params = {
            date: (date.getTime() / 1000),
            cost: parseFloat(cost),
            location_id: locationId,
            cost_per_player: parseFloat(costPerPlayer),
            email_flag: sendEmail,
            email_contents: emailContents,
            email_subject: emailSubject,
            self_in: (inOutGTD === 0),
        };
        if (sendEmail) {
            const authResponse = getAuthResponse();
            if (authResponse === undefined) {
                const client = window.google.accounts.oauth2.initTokenClient({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    scope: "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/userinfo.profile",
                    callback: response => {
                        setAuthResponse(response);
                        onCreateGame({
                            ...params,
                            auth: response,
                        });
                    },
                    error_callback: error => {
                        if (!window.confirm("No authentication token generated. Create game anyway?")) return;
                        onCreateGame(params);
                    }
                });
                client.requestAccessToken();
                return;
            }
            else {
                onCreateGame({
                    ...params,
                    auth: authResponse,
                });
            }
        }
        onCreateGame(params);
    }, [
        onCreateGame,
        date,
        cost,
        locationId,
        costPerPlayer,
        sendEmail,
        emailContents,
        emailSubject,
        inOutGTD,
        getAuthResponse,
        setAuthResponse,
        loaded,
    ]);

    const isSubmitEnabled = useMemo(() => {
        return validateFields({
            date,
            cost,
            locationId,
            costPerPlayer,
            sendEmail,
            emailContents,
        });
    }, [date, cost, locationId, costPerPlayer, sendEmail, emailContents]);

    return (
        <AddContext.Provider value={{
            date, setDate,
            cost, setCost,
            locationId, setLocationId,
            costPerPlayer, setCostPerPlayer,
            sendEmail, setSendEmail,
            locationName, setLocationName,
            locationNotes, setLocationNotes,
            locationURL, setLocationURL,
            inOutGTD, setInOutGTD,
            miscEmailText, setMiscEmailText,
            emailContents,
            onSubmit,
            isSubmitEnabled,
        }}>
            <div className={styles.detailsDiv}>
                <Label>
                    Add New Game
                </Label>
                <div className={styles.dateTextDiv}>
                    <DateTextField/>
                </div>
                <div className={styles.costTextField}>
                    <CostTextField/>
                </div>
                <div className={styles.locationDropdownDiv}>
                    <LocationDropdown/>
                </div>
                <div>
                    <Label>
                        Cost Per Player
                    </Label>
                    <div className={styles.costPerPlayerTextField}>
                        <CostPerPlayerTextField/>
                    </div>
                </div>
                <div className={styles.sendEmailCheckboxDiv}>
                    <SendEmailCheckbox/>
                </div>
                <div className={styles.inOutGtdDropdownDiv}>
                    <InOutGtdDropdown/>
                </div>
                <div>
                    <MiscEmailTextField/>
                    <EmailTextField/>
                </div>
                <div className={styles.addButtonDiv}>
                    <AddButton/>
                </div>
            </div>
        </AddContext.Provider>
    );
};

export { AddContext };
export default Add;
