import {
    useContext,
} from 'react';

import { EditContext } from './Edit';

import GameParticipant from './GameParticipant';

const GameParticipantList = props => {
    const {
        participants: gameParticipants,
    } = useContext(EditContext);

    return (
        <>
            {gameParticipants.map((participant, idx) =>
                <GameParticipant
                    idx={idx}
                    key={participant.player_id}
                    {...participant}
                />
            )}
        </>
    )
};

export default GameParticipantList;
