import {
    useCallback,
} from 'react';

import {
    IconButton,
} from '@fluentui/react/lib/Button';

import { authDELETE } from '../../util/fetch';

const iconProps = {
    iconName: "Delete",
};

const DeleteIconButton = props => {
    const {
        category,
        id,
        onDeleted = () => {},
    } = props;

    const onClick = useCallback(() => {
        if (!window.confirm("Are you sure you want to delete this item?")) {
            return;
        }
        authDELETE(`${category}/delete/${id}`)
        .then(({ error }) => {
            if (error) {
                alert(error);
                return;
            }
            onDeleted();
        });
    }, [onDeleted, id, category]);

    return (
        <IconButton
            iconProps={iconProps}
            title={"Delete"}
            onClick={onClick}
        />
    );
};

export default DeleteIconButton;