import {
    EditContext,
} from './Edit';

import ContextTextField from '../../ContextTextField';

const LastNameTextField = props => {
    return (
        <ContextTextField
            label={"Last Name"}
            value={"lastName"}
            setValue={"setLastName"}
            context={EditContext}
        />
    );
};

export default LastNameTextField;