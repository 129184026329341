import {
    useCallback,
} from 'react';

import {
    IconButton,
} from '@fluentui/react/lib/Button';

import { useNavigate } from 'react-router-dom';

const iconProps = {
    iconName: "Edit",
};

const EditIconButton = props => {
    const {
        category,
        id,
    } = props;

    const navigate = useNavigate();

    const onClick = useCallback(() => {
        navigate(`/${category}/edit/${id}`);
    }, [navigate, id, category]);

    return (
        <IconButton
            iconProps={iconProps}
            title={"Edit"}
            onClick={onClick}
        />
    );
};

export default EditIconButton;