import {
    useContext,
} from 'react';

import {
    PrimaryButton,
} from '@fluentui/react';

import { AddContext } from './Add';

const AddButton = props => {
    const {
        onSubmit,
        isSubmitEnabled = true,
    } = useContext(AddContext);

    return (
        <PrimaryButton
            onClick={onSubmit}
            text={"Add"}
            disabled={!isSubmitEnabled}
        />
    );
};

export default AddButton;
