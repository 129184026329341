import {
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import Plot from 'react-plotly.js';

import { DashboardContext } from './Dashboard';

import { useTheme } from '@fluentui/react';

import styles from './Dashboard.module.css';

const PlotConfig = {
    responsive: true,
};

const RunningSumBalance = props => {
    const [data, setData] = useState([]);

    const {
        historicalBalance,
    } = useContext(DashboardContext);

    const theme = useTheme();

    const layout = useMemo(() => {
        return {
            title: {
                text: 'Running Sum Balance',
                font: {
                    color: theme.palette.black,
                },
            },
            xaxis: {
                title: {
                    text: 'Date',
                    font: {
                        color: theme.palette.black,
                    },
                },
                tickfont: {
                    color: theme.palette.black,
                },
                gridcolor: theme.palette.neutralLight,
            },
            yaxis: {
                title: {
                    text: 'Balance',
                    font: {
                        color: theme.palette.black,
                    },
                },
                tickfont: {
                    color: theme.palette.black,
                },
                gridcolor: theme.palette.neutralLight,
            },
            plot_bgcolor: theme.palette.neutralLighterAlt,
            paper_bgcolor: theme.palette.white,
            autosize: true,
        };
    }, [theme]);

    useEffect(() => {
        historicalBalance.sort((a, b) => a[1] - b[1]);
        const x = [];
        const y = [];
        let balance_sum = 0;
        historicalBalance.forEach(([ balance, date ]) => {
            x.push(date);
            balance_sum += balance;
            y.push(balance_sum);
        });
        setData([{
            x,
            y,
            type: 'scatter',
            mode: 'lines',
        }]);
    }, [historicalBalance, setData]);

    return (
        <div className={styles.plotDiv}>
            <Plot
                data={data}
                layout={layout}
                className={styles.plot}
                config={PlotConfig}
            />
        </div>
    );
};

export default RunningSumBalance;
