import {
    useMemo,
} from 'react';

import {
    useTheme,
} from '@fluentui/react';

import {
    Link,
} from 'react-router-dom';

import BalanceOwedParticipantPaidButton from './BalanceOwedParticipantPaidButton';
import BalanceOwedParticipantAmountPaid from './BalanceOwedParticipantAmountPaid';

import styles from './Dashboard.module.css';

const BalanceOwedGameParticipant = props => {
    const {
        idx,
        game_id,
        participant_id,
        amount_paid,
        cost_per_player,
        date,
    } = props;

    const gameLinkBlock = useMemo(() => {
        return (
            <Link
                to={`/games/edit/${game_id}`}
                className={styles.dateLink}
            >
                {new Date(date * 1000).toLocaleDateString('en-US', {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                })}
            </Link>
        );
    }, [game_id, date]);

    const theme = useTheme();

    return (
        <div className={styles.participantRowDiv} style={{ backgroundColor: (idx % 2 === 0 ? theme.palette.neutralLighterAlt : theme.palette.neutralLighter)}}>
            <div className={styles.balanceOwedDateDiv}>
                {gameLinkBlock}
            </div>
            <div className={styles.participantPaidBtnDiv}>
                <BalanceOwedParticipantPaidButton
                    participant_id={participant_id}
                    amount_paid={amount_paid}
                    cost_per_player={cost_per_player}
                    game_id={game_id}
                />
            </div>
            <div className={styles.participantAmtPaidDiv}>
                <BalanceOwedParticipantAmountPaid
                    participant_id={participant_id}
                    amount_paid={amount_paid}
                    game_id={game_id}
                />
            </div>
        </div>
    );
};

export default BalanceOwedGameParticipant;
