import {
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import ContextDropdown from "../ContextDropdown";

import { authGET } from "../../util/fetch";

const DefaultCallback = () => {};

const ContextLocationDropdown = props => {
    const {
        context,
        setSelectedKey: _setSelectedKey,
        onSelectedCallback = DefaultCallback,
        useDefault = true,
    } = props;

    const {
        [_setSelectedKey]: setSelectedKey,
    } = useContext(context);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        authGET('locations')
        .then(({ error, locations }) => {
            if (error) {
                alert(error);
                return;
            }

            const opts = [];
            let default_location = undefined;
            let default_location_info = undefined;
            locations.forEach(location => {
                const{
                    location_id,
                    name,
                } = location;

                default_location_info = location;
                default_location = location_id;

                opts.push({
                    key: location_id,
                    text: name,
                    info: location,
                });
            });
            setOptions(opts);
            if (useDefault) {
                if (default_location !== undefined) {
                    setSelectedKey(default_location);
                }
                if (default_location_info !== undefined) {
                    onSelectedCallback(default_location_info);
                }
            }
        })
        .catch(error => {
            alert('Error:', error);
        });
    }, [
        setOptions,
        setSelectedKey,
        onSelectedCallback,
        useDefault,
    ]);

    const onSelectedCallback_ = useCallback(item => {
        const {
            key,
        } = item;
        for (let i = 0; i < options.length; i++) {
            const {
                key: location_id,
            } = options[i];
            if (location_id === key) {
                onSelectedCallback(options[i].info);
                break;
            }
        }
    }, [options, onSelectedCallback]);

    return (
        <ContextDropdown
            placeholder={"Select Location"}
            options={options}
            label={"Location"}
            {...props}
            onSelectedCallback={onSelectedCallback_}
        />
    )
};

export default ContextLocationDropdown;
