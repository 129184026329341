import { useContext } from 'react';

import {
    AddContext,
} from './Add';

import ContextTextField from '../../ContextTextField';

const EmailTextField = props => {
    const {
        sendEmail,
    } = useContext(AddContext);

    if (!sendEmail) return null;

    return (
        <ContextTextField
            label={"Email Contents"}
            value={"emailContents"}
            context={AddContext}
            multiline={true}
            readOnly={true}
            rows={15}
        />
    );
};

export default EmailTextField;