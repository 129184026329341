import {
    AddContext,
} from './Add';

import ContextTextField from '../../ContextTextField';

const NameTextField = props => {
    return (
        <ContextTextField
            label={"Name"}
            value={"name"}
            setValue={"setName"}
            context={AddContext}
        />
    );
};

export default NameTextField;