import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    AgGridReact,
} from 'ag-grid-react';

import {
    Checkbox,
    mergeStyles,
} from '@fluentui/react';

import { authGET } from '../../../util/fetch';
import { ThemeContext } from '../../App/App';

import EditIconButton from '../../EditIconButton';
import DeleteIconButton from '../../DeleteIconButton';
import ActiveFilter from './ActiveFilter';
import MinorFilter from './MinorFilter';

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import styles from './List.module.css';

const checkboxStyles = mergeStyles({
    padding: '10px',
});

const List = props => {
    const {
        theme,
    } = useContext(ThemeContext);

    const [rowData, setRowData] = useState([]);

    const getData = useCallback(() => {
        authGET('players')
        .then(({ error, players }) => {
            if (error) {
                alert(error);
                return;
            }
            setRowData(players);
        })
        .catch(error => {
            alert('Error:', error);
        });
    }, [setRowData]);

    const renderActionCell = useCallback(params => {
        const {
            data: {
                player_id,
            },
        } = params;

        return (
            <span>
                <EditIconButton
                    category={"players"}
                    id={player_id}
                />
                <DeleteIconButton
                    category={"players"}
                    id={player_id}
                    onDeleted={getData}
                />
            </span>
        );
    }, [getData]);

    const renderActiveCell = useCallback(params => {
        const {
            data: {
                active = false,
            },
        } = params;

        return (
            <span>
                <Checkbox
                    checked={active}
                    className={checkboxStyles}
                />
            </span>
        );
    }, []);

    const renderMinorCell = useCallback(params => {
        const {
            data: {
                minor = false,
            },
        } = params;

        return (
            <span>
                <Checkbox
                    checked={minor}
                    className={checkboxStyles}
                />
            </span>
        );
    }, []);

    const ColumnDefs = useMemo(() => {
        return [
            {
                headerName: "Actions",
                cellRenderer: renderActionCell,
                width: 100,
                sortable: false,
            },
            {
                field: "first_name",
                headerName: "First Name",
                filter: true,
                width: 150,
            },
            {
                field: "last_name",
                headerName: "Last Name",
                filter: true,
                width: 150,
            },
            {
                field: "email_address",
                headerName: "Email",
                filter: true,
                width: 250,
            },
            {
                headerName: "Active",
                field: "active",
                cellRenderer: renderActiveCell,
                filter: ActiveFilter,
                width: 80,
                sortable: true,
            },
            {
                headerName: "Minor",
                field: "minor",
                cellRenderer: renderMinorCell,
                filter: MinorFilter,
                width: 80,
                sortable: true,
            },
        ];
    }, [renderActionCell, renderActiveCell, renderMinorCell]);

    useEffect(() => {
        getData();
    }, [getData]);

    const gridTheme = useMemo(() => {
        if (theme === "dark") return 'ag-theme-alpine-dark';
        return 'ag-theme-alpine';
    }, [theme]);

    return (
        <div className={`${styles.grid} ${gridTheme}`}>
            <AgGridReact
                rowData={rowData}
                columnDefs={ColumnDefs}
            />
        </div>
    );
};

export default List;