import {
    createTheme,
} from "@fluentui/react";

export const darkTheme = createTheme({
    palette: {
        themePrimary: '#5691a6',
        themeLighterAlt: '#030607',
        themeLighter: '#0e171b',
        themeLight: '#1a2b32',
        themeTertiary: '#345763',
        themeSecondary: '#4c7f92',
        themeDarkAlt: '#639baf',
        themeDark: '#77a9bb',
        themeDarker: '#96becd',
        neutralLighterAlt: '#2b2b2b',
        neutralLighter: '#333333',
        neutralLight: '#414141',
        neutralQuaternaryAlt: '#4a4a4a',
        neutralQuaternary: '#515151',
        neutralTertiaryAlt: '#6f6f6f',
        neutralTertiary: '#fcfcfc',
        neutralSecondary: '#fcfcfc',
        neutralSecondaryAlt: '#fcfcfc',
        neutralPrimaryAlt: '#fdfdfd',
        neutralPrimary: '#fbfbfb',
        neutralDark: '#fefefe',
        black: '#fefefe',
        white: '#181d1f',
    },
});

export const defaultTheme = createTheme({
    palette: {
        themePrimary: '#0078d4',
        themeLighterAlt: '#eff6fc',
        themeLighter: '#deecf9',
        themeLight: '#c7e0f4',
        themeTertiary: '#71afe5',
        themeSecondary: '#2b88d8',
        themeDarkAlt: '#106ebe',
        themeDark: '#005a9e',
        themeDarker: '#004578',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    },
});

export const setLocalTheme = theme => {
    localStorage.setItem('theme', theme);
};

export const resolveTheme = theme => {
    if (theme === "dark") {
        return darkTheme;
    }
    return defaultTheme;
};

export const getLocalThemeName = () => {
    return localStorage.getItem('theme') || "default";
};

export const getLocalTheme = () => {
    return resolveTheme(getLocalThemeName());
};
