import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    AgGridReact,
} from 'ag-grid-react';

import { authGET } from '../../../util/fetch';
import { ThemeContext } from '../../App/App';

import EditIconButton from '../../EditIconButton';
import DeleteIconButton from '../../DeleteIconButton';

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import styles from './List.module.css';

const List = props => {
    const {
        theme,
    } = useContext(ThemeContext);

    const [rowData, setRowData] = useState([]);

    const getData = useCallback(() => {
        authGET('games')
        .then(({ error, games }) => {
            if (error) {
                alert(error);
                return;
            }
            setRowData(games);
        })
        .catch(error => {
            alert('Error:', error);
        });
    }, [setRowData]);

    const renderActionCell = useCallback(params => {
        const {
            data: {
                game_id,
            },
        } = params;

        return (
            <span>
                <EditIconButton
                    category={"games"}
                    id={game_id}
                />
                <DeleteIconButton
                    category={"games"}
                    id={game_id}
                    onDeleted={getData}
                />
            </span>
        );
    }, [getData]);

    const renderDateCell = useCallback(params => {
        const {
            value,
        } = params;

        return new Date(value * 1000).toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        });
    }, []);

    const renderNetCollectedCell = useCallback(params => {
        const {
            data: {
                cost,
                total_amount_paid,
            },
        } = params;
        return (-cost + total_amount_paid);
    }, []);

    const cellStyleNetCollectedCell = useCallback(params => {
        const {
            value,
        } = params;
        if (value === 0) {
            return undefined;
        }
        if (value < 0) {
            return {
                color: 'red',
            };
        }
        return {
            color: 'green',
        };
    }, []);

    const ColumnDefs = useMemo(() => {
        return [
            {
                headerName: "Actions",
                cellRenderer: renderActionCell,
                width: 100,
                sortable: false,
            },
            {
                field: "date",
                headerName: "Date",
                cellRenderer: renderDateCell,
                width: 200,
                sort: 'desc',
            },
            {
                field: "total_amount_paid",
                headerName: "Total Collected",
                filter: true,
                width: 150,
            },
            {
                headerName: "Net Collected",
                valueGetter: renderNetCollectedCell,
                cellStyle: cellStyleNetCollectedCell,
                filter: true,
                width: 150,
            },
            {
                field: "num_participants",
                headerName: "# Players",
                filter: true,
                width: 150,
            },
            {
                field: "cost",
                headerName: "Cost",
                filter: true,
                width: 150,
            },
            {
                field: "cost_per_player",
                headerName: "Cost Per Player",
                filter: true,
                width: 150,
            },
            {
                field: "location_name",
                headerName: "Location",
                filter: true,
                width: 300,
            },
        ];
    }, [
        renderActionCell,
        renderDateCell,
        renderNetCollectedCell,
        cellStyleNetCollectedCell,
    ]);

    useEffect(() => {
        getData();
    }, [getData]);

    const gridTheme = useMemo(() => {
        if (theme === "dark") return 'ag-theme-alpine-dark';
        return 'ag-theme-alpine';
    }, [theme]);

    return (
        <div className={`${styles.grid} ${gridTheme}`}>
            <AgGridReact
                rowData={rowData}
                columnDefs={ColumnDefs}
            />
        </div>
    );
};

export default List;