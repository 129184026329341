import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    AgGridReact,
} from 'ag-grid-react';

import { authGET } from '../../../util/fetch';
import { ThemeContext } from '../../App/App';

import EditIconButton from '../../EditIconButton';
import DeleteIconButton from '../../DeleteIconButton';

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import styles from './List.module.css';

const List = props => {
    const {
        theme,
    } = useContext(ThemeContext);

    const [rowData, setRowData] = useState([]);

    const getData = useCallback(() => {
        authGET('locations')
        .then(({ error, locations }) => {
            if (error) {
                alert(error);
                return;
            }
            setRowData(locations);
        })
        .catch(error => {
            alert('Error:', error);
        });
    }, [setRowData]);

    const renderActionCell = useCallback(params => {
        const {
            data: {
                location_id,
            },
        } = params;

        return (
            <span>
                <EditIconButton
                    category={"locations"}
                    id={location_id}
                />
                <DeleteIconButton
                    category={"locations"}
                    id={location_id}
                    onDeleted={getData}
                />
            </span>
        );
    }, [getData]);
    
    const ColumnDefs = useMemo(() => {
        return [
            {
                headerName: "Actions",
                cellRenderer: renderActionCell,
                width: 100,
                sortable: false,
            },
            {
                field: "name",
                headerName: "Name",
                filter: true,
                width: 275,
            },
            {
                field: "address",
                headerName: "Address",
                filter: true,
                width: 375,
            },
        ];
    }, [renderActionCell]);

    useEffect(() => {
        getData();
    }, [getData]);

    const gridTheme = useMemo(() => {
        if (theme === "dark") return 'ag-theme-alpine-dark';
        return 'ag-theme-alpine';
    }, [theme]);

    return (
        <div className={`${styles.grid} ${gridTheme}`}>
            <AgGridReact
                rowData={rowData}
                columnDefs={ColumnDefs}
            />
        </div>
    );
};

export default List;