import {
    useEffect,
    useMemo,
} from 'react';

import {
    useParams,
    useNavigate,
} from 'react-router-dom';

import AuthenticatedPage from '../AuthenticatedPage';
import List from './List';
import Add from './Add';
import Edit from './Edit';

// import styles from './Players.module.css';

const Players = props => {
    const {
        t,
        id,
    } = useParams();
    const navigate = useNavigate();

    const Content = useMemo(() => {
        if (t === "add") {
            return <Add/>;
        }
        if (t === "edit" && id !== undefined) {
            return <Edit/>;
        }
        if (t !== undefined ||
            id !== undefined) {
            return null;
        }
        return <List/>;
    }, [t, id]);

    useEffect(() => {
        if (Content === null) navigate('/players');
    }, [navigate, Content]);

    return (
        <AuthenticatedPage>
            {Content}
        </AuthenticatedPage>
    );
};

export default Players;
