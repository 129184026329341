import {
    useContext,
    useMemo,
} from 'react';

import {
    useNavigate,
} from 'react-router-dom';

import {
    Nav,
} from '@fluentui/react/lib/Nav';

import {
    useMediaQuery,
} from 'react-responsive';

import { AuthenticatedPageContext } from '../AuthenticatedPage';

const Navi = props => {
    const navigate = useNavigate();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const {
        showNavi,
    } = useContext(AuthenticatedPageContext);

    const items = useMemo(() => {
        return [
            {
                links: [
                    {
                        name: 'Dashboard',
                        url: '/dashboard',
                        key: 'dashboard',
                        isExpanded: true,
                    },
                    {
                        name: 'Games',
                        key: 'games',
                        isExpanded: true,
                        links: [
                            {
                                name: 'All Games',
                                url: '/games',
                                key: 'all_games',
                            },
                            {
                                name: 'Add Game',
                                url: '/games/add',
                                key: 'add_games',
                            }
                        ],
                    },
                    {
                        name: 'Players',
                        key: 'players',
                        isExpanded: true,
                        links: [
                            {
                                name: 'All Players',
                                url: '/players',
                                key: 'all_players',
                            },
                            {
                                name: 'Add Player',
                                url: '/players/add',
                                key: 'add_players',
                            }
                        ],
                    },
                    {
                        name: 'Locations',
                        key: 'locations',
                        isExpanded: true,
                        links: [
                            {
                                name: 'All Locations',
                                url: '/locations',
                                key: 'all_locations',
                            },
                            {
                                name: 'Add Location',
                                url: '/locations/add',
                                key: 'add_locations',
                            }
                        ],
                    },
                    {
                        name: 'Logout',
                        key: 'logout',
                        onClick: () => {
                            localStorage.removeItem('token');
                            localStorage.removeItem('google_credential');
                            navigate('/login');
                        },
                    },
                ],
            },
        ];
    }, [navigate]);

    if (isTabletOrMobile && !showNavi) return null;
    return (
        <Nav
            groups={items}
        />
    );
};

export default Navi;