import {
    useCallback,
    useContext,
} from 'react';

import {
    Dropdown,
} from '@fluentui/react';

const ContextDropdown = props => {
    const {
        context,
        selectedKey: _selectedKey,
        setSelectedKey: _setSelectedKey,
        onSelectedCallback = () => {},
        ...rest
    } = props;

    const {
        [_selectedKey] : selectedKey,
        [_setSelectedKey]: setSelectedKey,
    } = useContext(context);

    const onChange = useCallback((_, item) => {
        const {
            key,
        } = item;
        setSelectedKey(key);
        onSelectedCallback(item);
    }, [setSelectedKey, onSelectedCallback]);

    return (
        <Dropdown
            selectedKey={selectedKey}
            onChange={onChange}
            {...rest}
        />
    );
};

export default ContextDropdown;