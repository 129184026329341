import {
    useContext,
} from 'react';

import {
    PrimaryButton,
} from '@fluentui/react';

import { EditContext } from './Edit';

const UpdateButton = props => {
    const {
        onSubmit,
        isSubmitEnabled = true,
    } = useContext(EditContext);

    return (
        <PrimaryButton
            onClick={onSubmit}
            text={"Update"}
            disabled={!isSubmitEnabled}
        />
    );
};

export default UpdateButton;
