import {
    EditContext,
} from './Edit';

import ContextDateTimePicker from '../../ContextDateTimePicker';

const DateTextField = props => {
    return (
        <ContextDateTimePicker
            value={"date"}
            setValue={"setDate"}
            context={EditContext}
        />
    );
};

export default DateTextField;