import {
    EditContext,
} from './Edit';

import ContextTextField from '../../ContextTextField';

const CostTextField = props => {
    return (
        <ContextTextField
            label={"Cost"}
            value={"cost"}
            setValue={"setCost"}
            context={EditContext}
        />
    );
};

export default CostTextField;