import styles from './Header.module.css';

const Title = props => {
    return (
        <div className={styles.titleDiv}>
            <h1>Basketball Ledger</h1>
        </div>
    );
};

export default Title;