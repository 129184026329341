import {
    Label,
} from '@fluentui/react/lib/Label';

import { useTheme } from '@fluentui/react';

import RemoveGameParticipantButton from './RemoveGameParticipantButton';
import GameParticipantPaidButton from './GameParticipantPaidButton';
import GameParticipantAmountPaid from './GameParticipantAmountPaid';

import styles from './Edit.module.css';

const GameParticipant = props => {
    const {
        idx,
        participant_id,
        first_name,
        last_name,
        amount_paid,
    } = props;

    const theme = useTheme();

    return (
        <div className={styles.participantRowDiv} style={{ backgroundColor: (idx % 2 === 0 ? theme.palette.neutralLighterAlt : theme.palette.neutralLighter)}}>
            <div className={styles.removeParticipantBtnDiv}>
                <RemoveGameParticipantButton
                    participant_id={participant_id}
                />
            </div>
            <div className={styles.participantNameDiv}>
                <Label>
                    {first_name} {last_name}
                </Label>
            </div>
            <div className={styles.participantPaidBtnDiv}>
                <GameParticipantPaidButton
                    participant_id={participant_id}
                    amount_paid={amount_paid}
                />
            </div>
            <div className={styles.participantAmtPaidDiv}>
                <GameParticipantAmountPaid
                    participant_id={participant_id}
                    amount_paid={amount_paid}
                />
            </div>
        </div>
    );
};

export default GameParticipant;
