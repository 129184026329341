import {
    AddContext,
} from './Add';

import ContextTextField from '../../ContextTextField';

const MapsLinkTextField = props => {
    return (
        <ContextTextField
            label={"Maps URL"}
            value={"url"}
            setValue={"setURL"}
            context={AddContext}
        />
    );
};

export default MapsLinkTextField;