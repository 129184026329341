export const validateFields = fields => {
    const {
        firstName,
        emailAddress,
    } = fields;

    if (firstName === undefined || firstName === '') return false;
    if (emailAddress !== undefined && emailAddress !== '') {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress)) return false;
    }
    return true;
};
