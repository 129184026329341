import {
    EditContext,
} from './Edit';

import ContextTextField from '../../ContextTextField';

const FirstNameTextField = props => {
    return (
        <ContextTextField
            label={"First Name"}
            value={"firstName"}
            setValue={"setFirstName"}
            context={EditContext}
        />
    );
};

export default FirstNameTextField;