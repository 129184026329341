import {
    AddContext,
} from './Add';

import ContextTextField from '../../ContextTextField';

const CostPerPlayerTextField = props => {
    return (
        <ContextTextField
            value={"costPerPlayer"}
            setValue={"setCostPerPlayer"}
            context={AddContext}
        />
    );
};

export default CostPerPlayerTextField;